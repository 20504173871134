.app {
  position: relative;
  width: 100%;
  height: 100%;
  direction: rtl;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.appContent {
  flex: 1;
  overflow-y: auto;
}

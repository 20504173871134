.lectureTime {
  display: flex;
  flex-direction: column;
}

.lectureTimeFields {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.lectureTimeCommonFields {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.videoLink {
  flex: 1;
  margin-inline-end: 10px;
}

.lectureTimeActionButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700;800&display=swap");

:root {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  font-family: Arimo;
  height: 100%;

  --color-primary: #7a2a8c;
  --color-primary-light: #9d49b0;
  --color-primary-border: #9d49b0a0;
  --color-white: #ffffff;
  --color-white-soft: #ffffffb4;
  --color-content: #2e2626;
  --color-content-1: #212121;
  --color-content-2: #424242;
  --color-content-3: #a1a1a1;
  --color-content-4: #c4c4c4;
  --color-content-5: #e3e3e3;

  --color-table-odd-row: rgba(0, 0, 0, 0.04);

  --color-gray: #424242;
  --color-error: #ff0000;

  --color-order-icon: #f8d210;
  --color-organization-icon: #2ff3e0;
  --color-participant-icon: #fa26a0;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notes {
  width: 50%;
}

.fileName {
  padding: 20px 0;
  cursor: pointer;
  line-height: 30px;
  color: var(--color-primary-light);
  text-decoration: underline;
}

.container {
  margin: 20px;
  padding: 20px;
  background-color: var(--color-content-5);
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
}

.firstRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.subjectField {
  flex: 1;
}

.contentRow {
  margin-inline-end: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.noTravetCostCheckbox {
  min-width: 180px;
  margin-bottom: 15px;
}

.copyLinkButton {
  margin-bottom: 15px;
}

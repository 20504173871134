.section {
  width: 100%;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 20px;
}

.clickableTitle {
  cursor: pointer;
}

.titleText {
  font-size: 22px;
  font-weight: bold;
  color: var(--color-primary-border);
}

.paperContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 30px 10px 30px;
  width: 100%;
}

.mobilePaper {
  margin: 0 20px 10px 20px;
}

.container {
  margin: 10px 0 10px 20px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 20px 15px 20px 25px;
  cursor: pointer;
  width: 350px;
}

.content {
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.icon {
  margin-inline-end: 10px;
}

.orderIcon {
  color: var(--color-order-icon);
}

.organizationIcon {
  color: var(--color-organization-icon);
}

.participantIcon {
  color: var(--color-participant-icon);
}
